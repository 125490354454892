import common from "./common.json";
import DM from "./DM.json";
import SRS from "./SRS.json";
import questions from "./questions.json";
import framework from "./framework.json";

const en = {
  common,
  DM,
  SRS,
  questions,
  framework,
};

export default en;
