import React from "react";
import welcomeVideo from "../../assets/gif/welcome.mp4";

const HomeScreen = () => {
  return (
    <video
      src={welcomeVideo}
      autoPlay
      loop
      muted
      style={{ width: "100%" }}
      disablePictureInPicture
      controlsList="nodownload noremoteplayback"
    />
  );
};

export default HomeScreen;
