import React, { lazy, Suspense } from "react";
import { useRoutes, BrowserRouter } from "react-router-dom";
import { Spin } from "antd";
import { NotFound } from "../pages/NotFound";
import { AppLayout } from "../layout/AppLayout";
import { Roles } from "../constants/CommonEnum";
import { Protected } from "./Protected";
import HomeScreen from "../pages/Home/HomeScreen";

const OrganizacionScreen = lazy(
  () => import("../pages/Organization/OrganizationScreen"),
);
const MyOrganizacionScreen = lazy(
  () => import("../pages/Organization/MyOrganizationScreen"),
);
const ConversionFactorScreen = lazy(
  () => import("../pages/ConversionFactor/ConversionFactorScreen"),
);
const UserScreen = lazy(() => import("../pages/User/UserScreen"));
const MyUserScreen = lazy(() => import("../pages/User/MyUserScreen"));
const EinfScreen = lazy(() => import("../pages/Einf/EinfScreen"));
const MyEinfScreen = lazy(() => import("../pages/Einf/MyEinfScreen"));
const SRScreen = lazy(
  () =>
    import("../pages/SustainabilityReporting/SustainabilityReportingScreen"),
);
const MySRScreen = lazy(
  () =>
    import("../pages/SustainabilityReporting/MySustainabilityReportingScreen"),
);
const LicenseScreen = lazy(() => import("../pages/License/LicenseScreen"));
const ForbiddenScreen = lazy(() => import("../pages/Forbidden/Forbidden"));
const TemplateScreen = lazy(() => import("../pages/Template/TemplateScreen"));
const DoubleMaterialityReportScreen = lazy(
  () => import("../pages/DoubleMateriality/DoubleMaterialityScreen"),
);
const MyDoubleMaterialityReportScreen = lazy(
  () => import("../pages/DoubleMateriality/MyDoubleMaterialityScreen"),
);

const routesArray = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "forbidden",
    element: <ForbiddenScreen />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "einf/*",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <EinfScreen />
      </Protected>
    ),
  },
  {
    path: "my-einf/*",
    element: (
      <Protected
        route
        roles={[Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION]}
      >
        <MyEinfScreen />
      </Protected>
    ),
  },
  {
    path: "sr/:templateId/*",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <SRScreen />
      </Protected>
    ),
  },
  {
    path: "my-sr/:templateId/*",
    element: (
      <Protected
        route
        roles={[Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION]}
      >
        <MySRScreen />
      </Protected>
    ),
  },
  {
    path: "dm/*",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <DoubleMaterialityReportScreen />
      </Protected>
    ),
  },
  {
    path: "my-dm/*",
    element: (
      <Protected
        route
        roles={[Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION]}
      >
        <MyDoubleMaterialityReportScreen />
      </Protected>
    ),
  },
  {
    path: "organization/*",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <OrganizacionScreen />
      </Protected>
    ),
  },
  {
    path: "my-organization/*",
    element: (
      <Protected route roles={[Roles.ADMIN_ORGANIZATION]}>
        <MyOrganizacionScreen />
      </Protected>
    ),
  },
  {
    path: "conversion-factor",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <ConversionFactorScreen />
      </Protected>
    ),
  },
  {
    path: "template/*",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <TemplateScreen />
      </Protected>
    ),
  },
  {
    path: "user/*",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <UserScreen />
      </Protected>
    ),
  },
  {
    path: "my-user/*",
    element: (
      <Protected route roles={[Roles.ADMIN_ORGANIZATION]}>
        <MyUserScreen />
      </Protected>
    ),
  },
  {
    path: "license/:id",
    element: (
      <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]}>
        <LicenseScreen />
      </Protected>
    ),
  },
];

const AppRoutes = () => {
  const routes = useRoutes(routesArray);
  return routes;
};

export const Router = () => (
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Spin spinning />}>
        <AppRoutes />
      </Suspense>
    </AppLayout>
  </BrowserRouter>
);
